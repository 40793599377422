import createRoot from "react-dom";

import { configureStore } from "@reduxjs/toolkit";

import { Provider } from "react-redux";

import useReducer from "./features/Users";

import { HashRouter, Route, Routes } from "react-router-dom";

import "./index.css";

import Login from "./Login";

import HomeApp from "./Dashboard/Home/Home";

import Sync from "./Sync";

import CBT from "./Dashboard/CBT/CBT";

import Lock from "./Dashboard/Lock";

import Logout from "./Logout";

import Result from "./Dashboard/Result/Result";

import Students from "./Dashboard/Students/Students";

import Login_Student from "./Dashboard/Start/Login_Student";

import Start_Test from "./Dashboard/Quiz/Start_Quiz";

import End_Test from "./Dashboard/Quiz/End_Test";

import Exercise from "./Dashboard/Exercise/Exercise";

import Login_Work from "./Dashboard/Start/Login_Work";

import Start from "./Dashboard/Work/Start";

import New from "./Dashboard/New/New";

import Media from "./Dashboard/Media/Media";

import View from "./Dashboard/Media/View";

import Home from "./Dashboard/Media/Home";

import Invite from "./Dashboard/Media/Invite";

import Login_CBT from "./Dashboard/Quiz/Login_CBT";

import Quiz from "./Dashboard/Quiz/Quiz";

import Result_Quiz from "./Dashboard/Quiz/Result_Quiz";

import Logout_Quiz from "./Dashboard/Quiz/Logout_Quiz";

import GetStart from "./Dashboard/Exercise/Start";

import Edit from "./Dashboard/Exercise/Edit";

import Preview from "./Dashboard/Exercise/Preview";

import Results from "./Dashboard/Exercise/Results";

import Home_Work from "./Dashboard/Work/Home";

import Logout_work from "./Dashboard/Work/Logout";

import Note from "./Dashboard/Note/Note";

import Create_Note from "./Dashboard/Note/Create";

import Read from "./Dashboard/Read/Read";

import Open from "./Dashboard/Read/Open";

import Preview_CBT from "./Dashboard/CBT/Preview";

import Edit_CBT from "./Dashboard/CBT/Edit";

import Profile from "./Dashboard/Profile/Profile";

import Response from "./Dashboard/Chat/Response/Response";

import Starter from "./Dashborad_2/Starter";

import Report_Home from "./Dashborad_2/Home/Home";

import Create_Report from "./Dashborad_2/Result/Create";

import Upload_Report from "./Dashborad_2/Result/Upload";

import Report_Results from "./Dashborad_2/Result/Result";

import Report_Review from "./Dashborad_2/Review/Review";

import Report_Send from "./Dashborad_2/Send/Send";

import Transfer from "./Dashborad_2/Transfer/Transfer";

import Assign from "./Dashborad_2/Assign/Assign";

import Sheet from "./Dashborad_2/Broadsheet/Sheet";

import Broadsheet from "./Dashborad_2/Broadsheet/Broadsheet";

const store = configureStore({
  reducer: {
    user: useReducer,
  },
});

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route index path="/:id" element={<Login />} />
        <Route path="/:id/auth/:token" element={<Sync />} />
        <Route path={"/:id/home"} element={<HomeApp />} />
        <Route path={"/:id/students"} element={<Students />} />
        <Route path={"/:id/note"} element={<Note />} />
        <Route path={"/:id/note/create/:token"} element={<Create_Note />} />
        <Route path={"/:id/read/:token"} element={<Read />} />
        <Route path={"/:id/read/open"} element={<Open />} />
        <Route path={"/:id/cbt"} element={<CBT />} />
        <Route path={"/:id/settings"} element={<Profile />} />
        <Route path={"/:id/chatroom/response"} element={<Response />} />
        <Route path={"/:id/new/:type"} element={<New />} />
        <Route path={"/:id/cbt/preview/:token"} element={<Preview_CBT />} />
        <Route path={"/:id/cbt/:token/edit/"} element={<Edit_CBT />} />
        <Route path={"/:id/quiz"} element={<Login_CBT />} />
        <Route path={"/:id/quiz/home"} element={<Quiz />} />
        <Route path={"/:id/quiz/result"} element={<Result_Quiz />} />
        <Route path={"/:id/quiz/logout"} element={<Logout_Quiz />} />
        <Route path={"/:id/quiz/exam/:token"} element={<Login_Student />} />
        <Route path={"/:id/quiz/exam/:token/start"} element={<Start_Test />} />
        <Route path={"/:id/quiz/exam/:token/end"} element={<End_Test />} />
        <Route path={"/:id/results"} element={<Result />} />
        <Route path={"/:id/exercise"} element={<Exercise />} />
        <Route path={"/:id/media/home"} element={<Home />} />
        <Route path={"/:id/media/invite"} element={<Invite />} />
        <Route path={"/:id/media/q/:token"} element={<Media />} />
        <Route path={"/:id/media/:token/denied"} element={<Lock />} />
        <Route path={"/:id/media/:token/view/:course"} element={<View />} />
        <Route path={"/:id/exercise/:token/start"} element={<GetStart />} />
        <Route path={"/:id/exercise/:token/edit/:type"} element={<Edit />} />
        <Route path={"/:id/exercise/:token/preview"} element={<Preview />} />
        <Route path={"/:id/exercise/:token/result"} element={<Results />} />
        <Route path={"/:id/work/:token"} element={<Login_Work />} />
        <Route path={"/:id/work/:token/home"} element={<Home_Work />} />
        <Route path={"/:id/work/:token/start"} element={<Start />} />
        <Route path={"/:id/work/logout"} element={<Logout_work />} />

        <Route path={"/:id/report/start"} element={<Starter />} />
        <Route path={"/:id/report/assign"} element={<Assign />} />
        <Route path={"/:id/report"} element={<Report_Home />} />
        <Route path={"/:id/report/results"} element={<Report_Results />} />
        <Route path={"/:id/report/send"} element={<Report_Send />} />
        <Route path={"/:id/report/transfer"} element={<Transfer />} />
        <Route path={"/:id/report/create"} element={<Create_Report />} />
        <Route path={"/:id/report/create/:token"} element={<Upload_Report />} />
        <Route path={"/:id/report/review"} element={<Report_Review />} />
        <Route path={"/:id/report/sheet"} element={<Sheet />} />
        <Route path={"/:id/report/sheet/:teachid"} element={<Broadsheet />} />
        <Route path={"/:id/logout"} element={<Logout />} />
        <Route path={"/:id/qr/:studid"} element={<Students />} />

      </Routes>
    </HashRouter>
  );
}

createRoot.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
